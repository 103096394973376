:root {
  --color-primary: #42a5e4;
  --color-primary-darker1: #288fc5;
  --color-primary-darker2: #207dae;
  --color-primary-darker3: #1a6a94;
  --color-secondary: #282c34;
  --color-bg: #fefefe;
  --color-font: #2c2c2c;
  --color-font-light: #9f9f9f;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
  --header-height: 100px;
  color: var(--color-font);
  background-color: var(--color-bg);
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.App > section {
  padding-top: var(--header-height);
}

a {
  color: var(--color-font);
}

p, li {
  line-height: 2;
}
